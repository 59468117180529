import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import FormQueryEn from '../QueriesEn/FormQueryEn'; // Replace with the correct query file
import FormQueryHi from '../QueriesHi/FormQueryHi'; // Replace with the correct query file

const FormDataFetcher = ({ setFormData, currentLanguage }) => {
  const formContentQuery = currentLanguage === 'en' ? FormQueryEn : FormQueryHi;

  const { data } = useQuery(formContentQuery, {
    fetchPolicy: 'network-only',
    variables: { language: currentLanguage },
  });

  useEffect(() => {
    if (data) {
      const forms = data.nodeWebforms.nodes; // Adjust this to match the structure of your form query
      setFormData(forms);
      // console.log('Fetched form data:', forms);
    }
  }, [data, setFormData]);

};

export default FormDataFetcher;
