import React, { useEffect, useRef, useState } from "react";

const ReCaptchaDemo = ({ onValidate }) => {
  const captchaRef = useRef(null);
  const isRendered = useRef(false); // Track if reCAPTCHA is rendered
  const [token, setToken] = useState(null);

  useEffect(() => {
    const scriptSrc = "https://www.google.com/recaptcha/enterprise.js";

    // Check if script already exists to prevent duplicates
    if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
      const script = document.createElement("script");
      script.src = scriptSrc;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (window.grecaptcha && captchaRef.current && !isRendered.current) {
          renderCaptcha();
        }
      };
    } else if (window.grecaptcha && captchaRef.current && !isRendered.current) {
      renderCaptcha();
    }

    return () => {
      if (isRendered.current && captchaRef.current) {
        window.grecaptcha.enterprise.reset(); // Reset reCAPTCHA if rendered
      }
      isRendered.current = false; // Mark as unmounted
    };
  }, []);

  const renderCaptcha = () => {
    if (captchaRef.current && !isRendered.current) {
      window.grecaptcha.enterprise.render(captchaRef.current, {
        sitekey: "6LcskYoqAAAAAED2Ppo88l41Ur64yt4uUU5Idh6X", // Replace with your Enterprise site key
        action: "SUBMIT",
        callback: (response) => {
          console.log("reCAPTCHA Token:", response); // Check if token is generated
          if (onValidate) onValidate(response); // Pass token to parent
        },
        "error-callback": () => {
          console.error("reCAPTCHA validation failed.");
          if (onValidate) onValidate(null); // Notify parent of validation failure
        },
      });
      isRendered.current = true; // Mark as rendered
    }
  };

  return <div ref={captchaRef}></div>;
};

export default ReCaptchaDemo;
