import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import VideosQueryEn from '../QueriesEn/VideosQueryEn';
import VideosQueryHi from '../QueriesHi/VideosQueryHi';

const VideosDataFetcher = ({ setVideosData, currentLanguage }) => {
    const videosContentQuery =  currentLanguage === 'en' ?  VideosQueryEn : VideosQueryHi;
  
    const { data } = useQuery(videosContentQuery, {
      fetchPolicy: 'network-only',
      variables: { language: currentLanguage },
    });
  
    useEffect(() => {
      if (data) {
        const nodes = data.nodeVideos.nodes;
        setVideosData(nodes);
        // console.log('Fetched videosnodes data:', nodes);
      }
    }, [data, setVideosData]);
      return null;
  };
  
  export default VideosDataFetcher;
  
