import React from 'react';

const countryNames = {
    Afghanistan: 'Afghanistan',
    Albania: 'Albania',
    Algeria: 'Algeria',
    'American Samoa': 'American Samoa',
    Andorra: 'Andorra',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antarctica',
    'Antigua & Barbuda': 'Antigua & Barbuda',
    Argentina: 'Argentina',
    Armenia: 'Armenia',
    Aruba: 'Aruba',
    'Ascension Island': 'Ascension Island',
    Australia: 'Australia',
    Austria: 'Austria',
    Azerbaijan: 'Azerbaijan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahrain',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbados',
    Belarus: 'Belarus',
    Belgium: 'Belgium',
    Belize: 'Belize',
    Benin: 'Benin',
    Bermuda: 'Bermuda',
    Bhutan: 'Bhutan',
    Bolivia: 'Bolivia',
    'Bosnia & Herzegovina': 'Bosnia & Herzegovina',
    Botswana: 'Botswana',
    'Bouvet Island': 'Bouvet Island',
    Brazil: 'Brazil',
    'British Indian Ocean Territory': 'British Indian Ocean Territory',
    'British Virgin Islands': 'British Virgin Islands',
    Brunei: 'Brunei',
    Bulgaria: 'Bulgaria',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    Cambodia: 'Cambodia',
    Cameroon: 'Cameroon',
    Canada: 'Canada',
    'Canary Islands': 'Canary Islands',
    'Cape Verde': 'Cape Verde',
    'Caribbean Netherlands': 'Caribbean Netherlands',
    'Cayman Islands': 'Cayman Islands',
    'Central African Republic': 'Central African Republic',
    'Ceuta & Melilla': 'Ceuta & Melilla',
    Chad: 'Chad',
    Chile: 'Chile',
    China: 'China',
    'Christmas Island': 'Christmas Island',
    'Clipperton Island': 'Clipperton Island',
    'Cocos (Keeling) Islands': 'Cocos (Keeling) Islands',
    Colombia: 'Colombia',
    Comoros: 'Comoros',
    'Congo - Brazzaville': 'Congo - Brazzaville',
    'Congo - Kinshasa': 'Congo - Kinshasa',
    'Cook Islands': 'Cook Islands',
    'Costa Rica': 'Costa Rica',
    Croatia: 'Croatia',
    Cuba: 'Cuba',
    Curaçao: 'Curaçao',
    Cyprus: 'Cyprus',
    Czechia: 'Czechia',
    'Côte d’Ivoire': 'Côte d’Ivoire',
    Denmark: 'Denmark',
    'Diego Garcia': 'Diego Garcia',
    Djibouti: 'Djibouti',
    Dominica: 'Dominica',
    'Dominican Republic': 'Dominican Republic',
    Ecuador: 'Ecuador',
    Egypt: 'Egypt',
    'El Salvador': 'El Salvador',
    'Equatorial Guinea': 'Equatorial Guinea',
    Eritrea: 'Eritrea',
    Estonia: 'Estonia',
    Eswatini: 'Eswatini',
    Ethiopia: 'Ethiopia',
    'Falkland Islands': 'Falkland Islands',
    'Faroe Islands': 'Faroe Islands',
    Fiji: 'Fiji',
    Finland: 'Finland',
    France: 'France',
    'French Guiana': 'French Guiana',
    'French Polynesia': 'French Polynesia',
    'French Southern Territories': 'French Southern Territories',
    Gabon: 'Gabon',
    Gambia: 'Gambia',
    Georgia: 'Georgia',
    Germany: 'Germany',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Greece',
    Greenland: 'Greenland',
    Grenada: 'Grenada',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernsey',
    Guinea: 'Guinea',
    'Guinea-Bissau': 'Guinea-Bissau',
    Guyana: 'Guyana',
    Haiti: 'Haiti',
    'Heard & McDonald Islands': 'Heard & McDonald Islands',
    Honduras: 'Honduras',
    'Hong Kong SAR China': 'Hong Kong SAR China',
    Hungary: 'Hungary',
    Iceland: 'Iceland',
    India: 'India',
    Indonesia: 'Indonesia',
    Iran: 'Iran',
    Iraq: 'Iraq',
    Ireland: 'Ireland',
    'Isle of Man': 'Isle of Man',
    Israel: 'Israel',
    Italy: 'Italy',
    Jamaica: 'Jamaica',
    Japan: 'Japan',
    Jersey: 'Jersey',
    Jordan: 'Jordan',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    Kosovo: 'Kosovo',
    Kuwait: 'Kuwait',
    Kyrgyzstan: 'Kyrgyzstan',
    Laos: 'Laos',
    Latvia: 'Latvia',
    Lebanon: 'Lebanon',
    Lesotho: 'Lesotho',
    Liberia: 'Liberia',
    Libya: 'Libya',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lithuania',
    Luxembourg: 'Luxembourg',
    'Macao SAR China': 'Macao SAR China',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaysia',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malta',
    'Marshall Islands': 'Marshall Islands',
    Martinique: 'Martinique',
    Mauritania: 'Mauritania',
    Mauritius: 'Mauritius',
    Mayotte: 'Mayotte',
    Mexico: 'Mexico',
    Micronesia: 'Micronesia',
    Moldova: 'Moldova',
    Monaco: 'Monaco',
    Mongolia: 'Mongolia',
    Montenegro: 'Montenegro',
    Montserrat: 'Montserrat',
    Morocco: 'Morocco',
    Mozambique: 'Mozambique',
    'Myanmar (Burma)': 'Myanmar (Burma)',
    Namibia: 'Namibia',
    Nauru: 'Nauru',
    Nepal: 'Nepal',
    Netherlands: 'Netherlands',
    'Netherlands Antilles': 'Netherlands Antilles',
    'New Caledonia': 'New Caledonia',
    'New Zealand': 'New Zealand',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    'Norfolk Island': 'Norfolk Island',
    'Northern Mariana Islands': 'Northern Mariana Islands',
    'North Korea': 'North Korea',
    'North Macedonia': 'North Macedonia',
    Norway: 'Norway',
    Oman: 'Oman',
    'Outlying Oceania': 'Outlying Oceania',
    Pakistan: 'Pakistan',
    Palau: 'Palau',
    'Palestinian Territories': 'Palestinian Territories',
    Panama: 'Panama',
    'Papua New Guinea': 'Papua New Guinea',
    Paraguay: 'Paraguay',
    Peru: 'Peru',
    Philippines: 'Philippines',
    'Pitcairn Islands': 'Pitcairn Islands',
    Poland: 'Poland',
    Portugal: 'Portugal',
    'Puerto Rico': 'Puerto Rico',
    Qatar: 'Qatar',
    Romania: 'Romania',
    Russia: 'Russia',
    Rwanda: 'Rwanda',
    Réunion: 'Réunion',
    Samoa: 'Samoa',
    'San Marino': 'San Marino',
    Sark: 'Sark',
    'Saudi Arabia': 'Saudi Arabia',
    Senegal: 'Senegal',
    Serbia: 'Serbia',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapore: 'Singapore',
    'Sint Maarten': 'Sint Maarten',
    Slovakia: 'Slovakia',
    Slovenia: 'Slovenia',
    'Solomon Islands': 'Solomon Islands',
    Somalia: 'Somalia',
    'South Africa': 'South Africa',
    'South Georgia & South Sandwich Islands': 'South Georgia & South Sandwich Islands',
    'South Korea': 'South Korea',
    'South Sudan': 'South Sudan',
    Spain: 'Spain',
    'Sri Lanka': 'Sri Lanka',
    'St. Barthélemy': 'St. Barthélemy',
    'St. Helena': 'St. Helena',
    'St. Kitts & Nevis': 'St. Kitts & Nevis',
    'St. Lucia': 'St. Lucia',
    'St. Martin': 'St. Martin',
    'St. Pierre & Miquelon': 'St. Pierre & Miquelon',
    'St. Vincent & Grenadines': 'St. Vincent & Grenadines',
    Sudan: 'Sudan',
    Suriname: 'Suriname',
    'Svalbard & Jan Mayen': 'Svalbard & Jan Mayen',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    Syria: 'Syria',
    'São Tomé & Príncipe': 'São Tomé & Príncipe',
    Taiwan: 'Taiwan',
    Tajikistan: 'Tajikistan',
    Tanzania: 'Tanzania',
    Thailand: 'Thailand',
    'Timor-Leste': 'Timor-Leste',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    'Trinidad & Tobago': 'Trinidad & Tobago',
    'Tristan da Cunha': 'Tristan da Cunha',
    Tunisia: 'Tunisia',
    Turkmenistan: 'Turkmenistan',
    'Turks & Caicos Islands': 'Turks & Caicos Islands',
    Tuvalu: 'Tuvalu',
    Türkiye: 'Türkiye',
    'U.S. Outlying Islands': 'U.S. Outlying Islands',
    'U.S. Virgin Islands': 'U.S. Virgin Islands',
    Uganda: 'Uganda',
    Ukraine: 'Ukraine',
    'United Arab Emirates': 'United Arab Emirates',
    'United Kingdom': 'United Kingdom',
    'United States': 'United States',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Uzbekistan',
    Vanuatu: 'Vanuatu',
    'Vatican City': 'Vatican City',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnam',
    'Wallis & Futuna': 'Wallis & Futuna',
    'Western Sahara': 'Western Sahara',
    Yemen: 'Yemen',
    Zambia: 'Zambia',
    // Add more countries here...
    Zimbabwe: 'Zimbabwe',
};

const CountrySelect = ({ name, value, onChange, required }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2">Country
      <span className="text-red-500 ml-1">*</span>
      </label>
      <select
        name={name}
        required={required}
        onChange={onChange}
        value={value}    
        className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
      >
        <option value="">Select a Country</option>
        {Object.entries(countryNames).map(([key, country]) => (
          <option key={key} value={key}>{country}</option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5.516 7.548L10 12.032l4.484-4.484L16 8.548l-6 6-6-6z"/></svg>
      </div>
    </div>
  );
};

export default CountrySelect;
