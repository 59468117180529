import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();
  const pathParts = location.pathname.split("/").filter((part) => part);
  const [activeLink, setActiveLink] = useState(null);

  // Handle breadcrumb click
  const handleBreadcrumbClick = (index) => {
    setActiveLink(index);
  };

  // Routes to disable links for
  const disabledRoutes = ["programs", "campaigns"];

  return (
    <nav className="px-4 py-2">
      <ul className="flex flex-wrap gap-x-2 gap-y-1 text-sm sm:text-base">
        {/* Home Link */}
        <li>
          <Link
            to="/"
            onClick={() => handleBreadcrumbClick(-1)}
            className={`${
              activeLink === -1 ? "text-yellow-400" : "text-white"
            } font-bold sm:font-medium sm:hover:underline sm:hover:text-yellow-400`}
          >
            Home
          </Link>
          {pathParts.length > 0 && (
            <span className="text-white font-medium"> &gt; </span>
          )}
        </li>

        {/* Dynamic Breadcrumb Links */}
        {pathParts.map((part, index) => {
          const path = `/${pathParts.slice(0, index + 1).join("/")}`;
          const isDisabled = disabledRoutes.includes(part.toLowerCase());

          return (
            <li key={index} className="inline-flex items-center">
              {isDisabled ? (
                // Render as plain text for disabled routes
                <span className="text-white font-bold sm:font-medium truncate max-w-[120px] sm:max-w-none">
                  {part.charAt(0).toUpperCase() + part.slice(1).replace(/-/g, " ")}
                </span>
              ) : (
                // Render as a clickable link for enabled routes
                <Link
                  to={path}
                  onClick={() => handleBreadcrumbClick(index)}
                  className={`${
                    activeLink === index ? "text-yellow-400" : "text-white"
                  } font-bold sm:font-medium sm:hover:underline sm:hover:text-yellow-400 truncate max-w-[120px] sm:max-w-none`}
                >
                  {part.charAt(0).toUpperCase() + part.slice(1).replace(/-/g, " ")}
                </Link>
              )}

              {index < pathParts.length - 1 && (
                <span className="text-white font-medium"> &gt; </span>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
