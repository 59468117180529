// LeadershipDataFetcher.js

import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import LeadershipQueryEn from '../QueriesEn/LeadershipQueryEn';
import LeadershipQueryHi from '../QueriesHi/LeadershipQueryHi';

const LeadershipDataFetcher = ({ setLeadershipData, currentLanguage }) => {
  const leadershipQuery = currentLanguage === 'en' ? LeadershipQueryEn : LeadershipQueryHi;

  const { data: leadershipQueryData } = useQuery(leadershipQuery, {
    fetchPolicy: 'network-only',
    variables: { language: currentLanguage },
  });

  useEffect(() => {
    if (leadershipQueryData) {
      const leadershipData = leadershipQueryData.nodeLeaderships.nodes;
      setLeadershipData(leadershipData);
      // console.log('Fetched leadership data:', leadershipData);
    }
  }, [leadershipQueryData, setLeadershipData]);
  return null;
};

export default LeadershipDataFetcher;
