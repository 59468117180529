import { gql } from "@apollo/client";

const EventsQueryEn = gql`
  query MyQuery {
    nodeEvents(first: 100, langcode: "en") {
      nodes {
        id
        path
        title
        __typename
        imageAbout {
          url
          alt
        }
        body {
          value
        }
        commonBanner {
          ... on MediaCommonBanner {
            id
            name
            mediaImage2 {
            url
            alt
          }
          }
        }
      }
    }
  }
`;

export default EventsQueryEn;
