import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import MainNavigationMenuEn from '../QueriesEn/MainNavigationMenu';
import MainNavigationMenuHi from '../QueriesHi/MainNavigationMenuHi';

const MenuFetcher = ({ setMenuItems, currentLanguage }) => {
  const mainNavigationQuery = currentLanguage === 'en' ? MainNavigationMenuEn : MainNavigationMenuHi;

  const { data } = useQuery(mainNavigationQuery, {
    fetchPolicy: 'network-only', 
  });

  useEffect(() => {
    if (data) {
      // Process menu items to include children URLs
      const processedMenuItems = data.menu.items.map(menuItem => {
        const childrenUrls = menuItem.children?.map(child => child.url) || [];
        return {
          ...menuItem,
          childrenUrls: childrenUrls,
        };
      });
      // console.log('Fetched processedMenuItems data:', processedMenuItems);
      setMenuItems(processedMenuItems);
    }
  }, [data, setMenuItems, currentLanguage]);

  return null;
};

export default MenuFetcher;
