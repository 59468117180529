import React, { useState } from "react";

const SubscribeForm = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const getCsrfToken = async () => {
    try {
      const response = await fetch(
        "https://shikshacms.shikshainitiative.org/session/token?_format=json"
      );
      if (!response.ok) throw new Error("Failed to retrieve CSRF token");
      return await response.text();
    } catch (error) {
      console.error("Error fetching CSRF token:", error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      const csrfToken = await getCsrfToken();
      const requestData = {
        mail: [email],
        field_first_name: [firstName],
        field_last_name: [lastName],
      };

      const response = await fetch(
        "https://shikshacms.shikshainitiative.org/entity/simplenews_subscriber?_format=json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CSRF-Token": csrfToken,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error("Failed to subscribe. " + errorText);
      }

      setMessage("Subscription successful!");

      // Reset form fields after successful submission
      setEmail("");
      setFirstName("");
      setLastName("");
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 max-w-md mx-auto p-4 sm:p-6">
      <div className="flex items-center justify-between p-4 border-b dark:border-gray-600">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
          Subscribe to Newsletter
        </h3>
        <button
          type="button"
          onClick={onClose}
          className="text-gray-400 bg-transparent hover:bg-gray-200 rounded-lg text-lg w-10 h-10 flex justify-center items-center dark:hover:bg-gray-600"
        >
          <span aria-hidden="true" className="text-2xl">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit} className="p-4">
        <input
          type="email"
          placeholder="Email*"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="mb-2 w-full p-2 border rounded text-black sm:p-3"
        />
        <input
          type="text"
          placeholder="First Name*"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          className="mb-2 w-full p-2 border rounded text-black sm:p-3"
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className="mb-4 w-full p-2 border rounded text-black sm:p-3"
        />

        <button
          type="submit"
          disabled={loading}
          className="bg-blue-700 text-white py-2 px-4 rounded w-full sm:py-3"
        >
          {loading ? "Subscribing..." : "Subscribe"}
        </button>
        {/* Message will appear below the submit button */}
        {message && (
          <p
            className={`mt-4 text-center ${
              message.startsWith("Error") ? "text-red-600" : "text-green-600"
            }`}
          >
            {message}
          </p>
        )}
      </form>
    </div>
  );
};

export default SubscribeForm;
