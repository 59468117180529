import React from 'react';
import { Link } from 'react-router-dom';

const ServerError = () => (
  <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
    <h1 className="text-6xl font-bold text-gray-800">500</h1>
    <p className="text-lg text-gray-600">Internal Server Error. Something went wrong on our end.</p>
    <Link
      to="/"
      className="mt-4 px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
    >
      Go Back Home
    </Link>
  </div>
);

export default ServerError;
