import React, { useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";
import { Helmet } from "react-helmet";
import Breadcrumb from "./Breadcrumb";
import "../../index.css";

const EventsDetail = ({ eventsData, currentLanguage }) => {
  const { eventsId } = useParams();
  const events = eventsData.find((e) => e.path.split("/").pop() === eventsId);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (!events) return <div>Events not found</div>;

  const uniqueTitles = new Set();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="relative z-0">
      <div className="relative">
        {events.commonBanner?.mediaImage2 && (
          <img
            src={events.commonBanner.mediaImage2.url}
            className="w-full object-cover lg:mt-[48px] h-[173px]"
            alt={events.commonBanner.mediaImage2.alt}
          />
        )}
        <div className="absolute bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.3)] p-2 sm:p-4">
          <Breadcrumb />
        </div>
      </div>
      <div className="mx-auto px-[40px] mt-6">
        <div className="flex flex-col sm:flex-row items-center mb-[35px]">
          <div className="right-border-around relative w-[250px] mr-[40px] pt-15 pr-15 pb-0 pl-0">
            {events.imageAbout?.url && (
              <img
                src={events.imageAbout.url}
                alt={events.imageAbout.alt}
                className="relative w-full z-10"
              />
            )}
          </div>
          <Helmet>
            <title>{events.title}</title>
          </Helmet>
          <h1 className="text-[15px] font-bold">
            <strong className="block font-medium text-[22px] mb-[10px]">
              {events.title}
            </strong>
          </h1>
        </div>
        <div
          className="mb-10 text-justify"
          dangerouslySetInnerHTML={{ __html: events.body?.value || "" }}
        />
      </div>
      <div className="bg-gray-300 pb-8">
        <div className="mx-auto px-[40px] pt-5">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
            MORE EVENTS
            <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
              <img
                src={SvgShikshaFlower}
                alt="headingflower"
                className="h-8 sm:h-10 lg:h-12 relative z-10 bg-gray-300 p-1 sm:p-2"
              />
            </div>
          </h2>
          <Slider {...sliderSettings} className="mt-[45px]">
            {eventsData
              .filter(
                (event) =>
                  event.path.split("/").pop() !== eventsId &&
                  !uniqueTitles.has(event.title) &&
                  uniqueTitles.add(event.title)
              )
              .map((event) => (
                <div key={event.id} className="item">
                  <Link
                    className="group block relative text-center overflow-hidden"
                    to={`/${
                      currentLanguage === "en" ? "" : "hi/"
                    }events/${event.path.split("/").pop()}`}
                  >
                    {/* Updated card design */}
                    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <div className="relative w-full h-[200px] overflow-hidden rounded-t-lg bg-gray-200">
                        <img
                          src={event.imageAbout.url}
                          alt={event.imageAbout.alt}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="p-5">
                        <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white line-clamp-2">
                          {event.title}
                        </h5>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                          {event.description}
                        </p>
                        <a
                          href={`/${
                            currentLanguage === "en" ? "" : "hi/"
                          }events/${event.path.split("/").pop()}`}
                          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Read more
                          <svg
                            className="rtl:rotate-180 w-3.5 h-3.5 ml-2"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5h12m0 0L9 1m4 4L9 9"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default EventsDetail;
