import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../../index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";
import Eventshome from "./Eventshome";
import ProgramandCampaignHome from "./ProgramandCampaignHome";
import InvolvedHome from "./InvolvedHome";
import Testimonials from "./Testimonials"; // Adjust the path if the file is in a different folder
import { Helmet } from "react-helmet";

const HomePage = ({
  homePageData,
  currentLanguage,
  eventsData = [],
  programAndCampaignData = [],
}) => {
  const data = homePageData && homePageData.length > 0 ? homePageData[0] : null;

  const {
    bannerVideo,
    impactSection = [],
    impactTitle,
    impactSectionDescription,
    impactSectionImage = [],
    title,
    body,
    testimonials = [],
  } = data || {};
  //  console.log("home content display page checking programAndCampaignData  " ,programAndCampaignData);
  const [counts, setCounts] = useState(Array(impactSection.length).fill(0));

  const startCounting = (value, index) => {
    const step = Math.ceil(value / 100);
    let count = 0;

    const intervalId = setInterval(() => {
      count += step;
      if (count >= value) {
        clearInterval(intervalId);
        count = value;
      }
      setCounts((prevCounts) => {
        const newCounts = [...prevCounts];
        newCounts[index] = count;
        return newCounts;
      });
    }, 20);
  };

  useEffect(() => {
    if (impactSection.length > 0) {
      impactSection.forEach((impact, index) => {
        startCounting(parseInt(impact.numberOfReachAndImpact), index);
      });
    }
  }, [impactSection]);

  // Slick slider settings with arrows enabled
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true, // Enables previous/next buttons
  };

  const openModal = (url, title) => {
    // Implement your modal opening logic here
    console.log("Opening modal for:", title);
  };

  return (
    <>
      <div className="relative">
        <div className="relative w-full h-[36rem] lg:h-[36rem] sm:h-[325.375px] xs:h-[175.198px] xxs:h-[175.198px]">
          {bannerVideo && bannerVideo.mediaVideoFile?.url && (
            <div className="relative w-full h-full">
              <video
                className="absolute inset-0 object-cover w-full h-auto lg:h-[36rem] sm:h-[325.375px] xs:h-[175.198px] xxs:h-[175.198px]"
                autoPlay
                muted
                loop
                src={bannerVideo.mediaVideoFile.url}
                style={{ objectFit: "cover" }}
              />
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70"></div>
            </div>
          )}
        </div>
      </div>
      <Helmet>
        <title>{title}</title> {/* Set dynamic title here */}
      </Helmet>
      <div className="mx-auto px-[40px] pt-10">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          {title}
          <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
            <img
              src={SvgShikshaFlower}
              alt="headingflower"
              className="h-8 sm:h-10 lg:h-12  bg-white p-1 sm:p-2"
            />
          </div>
        </h1>
        <div
          className="text-xs sm:text-sm lg:text-base text-justify lg:pb-10"
          dangerouslySetInnerHTML={{ __html: body?.value }}
        />
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          {impactTitle}
          <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
            <img
              src={SvgShikshaFlower}
              alt="headingflower"
              className="h-8 sm:h-10 lg:h-12  bg-white p-1 sm:p-2"
            />
          </div>
        </h2>

        <div className="flex flex-col lg:flex-row gap-8 items-start">
          <div className="w-full lg:w-2/3 p-0 lg:px-5">
            <p className="text-xs sm:text-sm lg:text-base text-justify mb-10 lg:mb-0">
              {impactSectionDescription}
            </p>

            <div className="grid grid-cols-2 md:grid-cols-2 gap-8 p-0 lg:px-[20px] lg:py-[15px]">
              {impactSection.length > 0 &&
                impactSection.map((impact, index) => (
                  <div
                    key={impact.id}
                    className="flex flex-col items-start mb-8"
                  >
                    <h3 className="text-xl font-semibold">
                      {impact.titleOfReachAndImpact}
                    </h3>
                    <p className="text-2xl sm:text-3xl lg:text-4xl font-bold text-navy-blue mt-2">
                      {counts[index] || 0}
                      <sup>+</sup>
                    </p>
                  </div>
                ))}
            </div>
          </div>

          <div className="w-full lg:w-1/3">
            <Slider {...settings}>
              {impactSectionImage.length > 0 &&
                impactSectionImage.map((image, index) => {
                  const mediaImage = image.mediaImage || {};
                  if (!mediaImage.url) {
                    return null; // Skip rendering if no URL is available
                  }
                  return (
                    <div key={index}>
                      <a
                        href={mediaImage.url}
                        onClick={(e) => {
                          e.preventDefault();
                          openModal(mediaImage.url, `Image ${index + 1}`);
                        }}
                      >
                        <img
                          src={mediaImage.url}
                          alt={mediaImage.alt || `Image ${index + 1}`}
                          className="rounded-xl w-full object-cover"
                        />
                      </a>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </div>
      <Eventshome eventsData={eventsData} currentLanguage={currentLanguage} />
      <ProgramandCampaignHome programAndCampaignData={programAndCampaignData} />
      <InvolvedHome />
      <Testimonials testimonials={testimonials} />
    </>
  );
};

export default HomePage;
