import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import FooterQueryEn from '../QueriesEn/FooterQueryEn'; // Your English footer menu query
import FooterQueryHi from '../QueriesHi/FooterQueryHi'; // Your Hindi footer menu query

const FooterMenuFetcher = ({ setFooterMenuItems, currentLanguage }) => {
  // Select query based on current language
  const footerMenuQuery = currentLanguage === 'en' ? FooterQueryEn : FooterQueryHi;

  const { data } = useQuery(footerMenuQuery, {
    fetchPolicy: 'network-only', 
  });

  useEffect(() => {
    if (data) {
      // Process footer menu items if children URLs are required
      const processedMenuItems = data.menu.items.map(menuItem => ({
        ...menuItem,
        childrenUrls: menuItem.children?.map(child => child.url) || [],
      }));
      setFooterMenuItems(processedMenuItems);
    }
  }, [data, setFooterMenuItems, currentLanguage]);

  return null;
};

export default FooterMenuFetcher;
