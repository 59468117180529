import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuDropdown from "./MenuDropdown";
import ShikshaLogo from "../shiksha_logo_white.svg";

const Navbar = ({
  menuItems,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  currentLanguage,
  switchLanguage,
}) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [isTransparent, setIsTransparent] = useState(true);

  const getChildren = (menuItem) => menuItem.children || [];

  const closeMenu = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 && isMobileMenuOpen) {
        closeMenu();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      // Change navbar background if not on the home page or scroll is greater than 50px
      if (currentPath === "/" && scrollTop <= 50) {
        setIsTransparent(true);
      } else {
        setIsTransparent(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentPath]);

  const handleLinkClick = (e, menuItemUrl) => {
    if (currentPath === menuItemUrl) {
      // Prevent default action if clicking on the same path
      e.preventDefault();
      closeMenu();
    } else {
      closeMenu();
    }
  };

  return (
    <nav
      className={`fixed w-full z-10 top-0 transition-all duration-300 ${
        currentPath === "/" && isTransparent ? "bg-transparent" : "bg-navy-blue"
      } p-3`}
    >
      <div className="mx-auto px-[40px] xs:px-[20px] flex items-center justify-between flex-wrap relative">
        <div className="flex items-center">
          <Link to="/">
            <img src={ShikshaLogo} alt="logo" className="h-12 w-auto mr-2" />
          </Link>
        </div>
        <div className="block lg:hidden flex items-center">
          {/* <div className="relative mx-2">
            <div className="flex items-center">
              <span
                className={`text-white ${
                  currentLanguage === "en" ? "font-bold text-yellow-400" : ""
                }`}
              >
                EN
              </span>
              <button
                onClick={switchLanguage}
                className={`relative mx-2 w-12 h-6 bg-gray-300 rounded-full flex items-center ${
                  currentLanguage === "en" ? "justify-start" : "justify-end"
                } p-1 cursor-pointer`}
              >
                <div className="w-4 h-4 bg-navy-blue rounded-full"></div>
              </button>
              <span
                className={`text-white ${
                  currentLanguage === "hi" ? "font-bold text-yellow-400" : ""
                }`}
              >
                HI
              </span>
            </div>
          </div> */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMobileMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:items-center lg:w-auto">
          <div className="text-sm lg:flex-grow lg:flex lg:justify-end">
            {menuItems.map((menuItem) => {
              if (menuItem.title === "Contact Us") {
                return (
                  <Link
                    key={menuItem.title}
                    to={menuItem.url}
                    className="text-white px-3 pt-[4px] rounded-md text-sm font-medium"
                  >
                    {menuItem.title}
                  </Link>
                );
              }
              return (
                <MenuDropdown
                  key={menuItem.title}
                  title={menuItem.title}
                  items={getChildren(menuItem)}
                  closeMenu={closeMenu}
                  currentPath={currentPath}
                />
              );
            })}
          </div>
          {/* <div className="relative mx-2">
            <div className="flex items-center">
              <span
                className={`text-white ${
                  currentLanguage === "en" ? "font-bold text-yellow-400" : ""
                }`}
              >
                EN
              </span>
              <button
                onClick={switchLanguage}
                className={`relative mx-2 w-12 h-6 bg-gray-300 rounded-full flex items-center ${
                  currentLanguage === "en" ? "justify-start" : "justify-end"
                } p-1 cursor-pointer`}
              >
                <div className="w-4 h-4 bg-navy-blue rounded-full"></div>
              </button>
              <span
                className={`text-white ${
                  currentLanguage === "hi" ? "font-bold text-yellow-400" : ""
                }`}
              >
                HI
              </span>
            </div>
          </div> */}
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="fixed w-72 right-0 top-0 bg-white h-full overflow-y-auto p-6 transition-all duration-500 ease-in-out shadow-lg z-50">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={closeMenu}
              className="text-navy-blue focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="#000000"
                viewBox="0 0 311 311.07733"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0"
                />
                <path d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0" />
              </svg>
            </button>
          </div>
          <div className="space-y-4">
            {menuItems.map((menuItem) => {
              if (!getChildren(menuItem).length || menuItem.title === "Contact Us") {
                return (
                  <Link
                    key={menuItem.title}
                    to={menuItem.url}
                    className={`block text-black font-medium px-2 pt-[4px] rounded-md ${
                      currentPath === menuItem.url ? "bg-gray-200" : ""
                    }`}
                    onClick={(e) => handleLinkClick(e, menuItem.url)}
                  >
                    {menuItem.title}
                  </Link>
                );
              }

              return (
                <div key={menuItem.title}>
                  <MenuDropdown
                    key={menuItem.title}
                    title={menuItem.title}
                    items={getChildren(menuItem)}
                    closeMenu={closeMenu}
                    currentPath={currentPath}
                    isMobile={true} 
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
