import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import AnnualReportQueryEn from '../QueriesEn/AnnualReportQueryEn';
import AnnualReportQueryHi from '../QueriesHi/AnnualReportQueryHi';

const AnnualReportDataFetcher = ({ setAnnualReportData, currentLanguage }) => {
    const annualReportContentQuery =  currentLanguage === 'en' ? AnnualReportQueryEn : AnnualReportQueryHi;
  
    const {data } = useQuery(annualReportContentQuery, {
      fetchPolicy: 'network-only',
      variables: { language: currentLanguage },
    });
  
    useEffect(() => {
      if (data) {
        const nodes = data.nodeAnnualReports.nodes;
        setAnnualReportData(nodes);
        // console.log('Fetched AnnualReportData data:', nodes);
      }
    }, [data, setAnnualReportData]);
    return null;
  };
  
  export default AnnualReportDataFetcher;
  
