import React, { useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Breadcrumb from "./Breadcrumb";
import { Helmet } from "react-helmet";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";

const StoriesDetail = ({ storiesData, currentLanguage }) => {
  const { storiesId } = useParams();
  const stories = storiesData.find(
    (e) => e.path.split("/").pop() === storiesId
  );
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [location]);

  const uniqueTitles = new Set();

  // Slick carousel settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="relative z-0">
      <div className="relative">
        {stories.commonBanner?.mediaImage2 && (
          <img
            src={stories.commonBanner.mediaImage2.url}
            className="w-full object-cover lg:mt-[48px] h-[175px]"
            alt={stories.commonBanner.mediaImage2.alt}
          />
        )}
        {/* Breadcrumb overlay on the banner */}
        <div className="absolute bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.3)] p-2 sm:p-4">
          <Breadcrumb /> {/* Render the Breadcrumb component here */}
        </div>
      </div>

      <div className="mx-auto px-[40px] mt-6">
        <div className="flex flex-col sm:flex-row items-center mb-[35px]">
          <div className="right-border-around relative w-full sm:w-[250px] sm:mr-[40px] pt-15 pr-15 pb-0 pl-0 mb-4 sm:mb-0">
            {stories.imageAbout.url && (
              <img
                src={stories.imageAbout.url}
                alt={stories.imageAbout.alt}
                className="relative w-full z-10"
              />
            )}
          </div>
          <Helmet>
            <title>{stories.title}</title> 
          </Helmet>
          <h1 className="text-[15px] font-bold text-center sm:text-left">
            <strong className="block font-medium text-[22px] mb-[10px]">
              {stories.title}
            </strong>
          </h1>
        </div>
        <div
          className="mb-10 text-justify"
          dangerouslySetInnerHTML={{ __html: stories.body?.value || "" }}
        />
      </div>

      <div className="bg-gray-300 pb-8">
        <div className="mx-auto px-[40px] pt-5">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
            MORE STORIES AND TESTIMONIALS
            <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
              <img
                src={SvgShikshaFlower}
                alt="headingflower"
                className="h-8 sm:h-10 lg:h-12 relative z-10 bg-gray-300 p-1 sm:p-2"
              />
            </div>
          </h2>
          <Slider {...sliderSettings} className="mt-[45px]">
            {storiesData
              .filter(
                (story) =>
                  story.path.split("/").pop() !== storiesId &&
                  !uniqueTitles.has(story.title) &&
                  uniqueTitles.add(story.title)
              )
              .map((story) => (
                <div key={story.id} className="item">
                  <Link
                    className="group block relative text-center overflow-hidden"
                    to={`/${
                      currentLanguage === "en" ? "" : "hi/"
                    }stories/${story.path.split("/").pop()}`}
                  >
                    {/* Card Layout */}
                    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                      <div className="relative w-full h-[200px] overflow-hidden rounded-t-lg bg-gray-200">
                        <img
                          src={story.imageAbout.url}
                          alt={story.imageAbout.alt}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="p-5">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white line-clamp-1">
                          {story.title}
                        </h5>
                        <a
                          href={`/${
                            currentLanguage === "en" ? "" : "hi/"
                          }stories/${story.path.split("/").pop()}`}
                          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-navy-blue rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-navy-blue dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Read more
                          <svg
                            className="w-3.5 h-3.5 ml-2"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5h12m0 0L9 1m4 4L9 9"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default StoriesDetail;
