import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import HomePageQueryEn from '../QueriesEn/HomePageQueryEn';
import HomePageQueryHi from '../QueriesHi/HomePageQueryHi';

const HomePageDataFetcher = ({ setHomePageData, currentLanguage }) => {
    const homePageContentQuery =  currentLanguage === 'en' ? HomePageQueryEn : HomePageQueryHi;
  
    const { data } = useQuery(homePageContentQuery, {
      fetchPolicy: 'network-only',
      variables: { language: currentLanguage },
    });
  
    useEffect(() => {
      if (data) {
        const nodes = data.nodeHomePages.nodes;
        setHomePageData(nodes);
        // console.log('Fetched HomePageQuery data:', nodes);
      }
    }, [data, setHomePageData]);

    return null;
  };
  
  export default HomePageDataFetcher;
  
