import { gql } from '@apollo/client';

const StoriesQueryEn = gql`
query MyQuery {
  nodeStoriesOfChangeAndTestimonia(first: 10, langcode: "en") {
    nodes {
      title
      path
      __typename
      body {
        value
      }
      imageAbout {
        url
        alt
      }
      commonBanner {
        ... on MediaCommonBanner {
          id
          name
          mediaImage2 {
            url
            alt
          }
        }
      }
    }
  }
}
`;

export default StoriesQueryEn;