import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import UmangQueryEn from '../QueriesEn/UmangQueryEn';

const UmangDataFetcher = ({ setUmangData }) => {
    const umangContentQuery = UmangQueryEn
  
    const { data } = useQuery(umangContentQuery, {
      fetchPolicy: 'network-only',
    });
  
    useEffect(() => {
      if (data) {
        const nodes = data.nodeResumeUploads.nodes;
        setUmangData(nodes);
        // console.log('Fetched umangnodes data:', nodes);
      }
    }, [data, setUmangData]);

    return null;
  };
  
  export default UmangDataFetcher;
  
