import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import WorkingModelQueryEn from '../QueriesEn/WorkingModelQueryEn';
import WorkingModelQueryHi from '../QueriesHi/WorkingModelQueryHi';

const WorkingModelDataFetcher = ({ setWorkingModelData, currentLanguage }) => {
  const workingModelQuery = currentLanguage === 'en' ? WorkingModelQueryEn : WorkingModelQueryHi;

  const {  data } = useQuery(workingModelQuery, {
    fetchPolicy: 'network-only',
    variables: { language: currentLanguage },
  });

  useEffect(() => {
    if (data) {
      const nodes = data.nodeWorkingModels.nodes;
      setWorkingModelData(nodes);
      // console.log('Fetched Working Model data:', nodes);
    }
  }, [data, setWorkingModelData]);

  return null;
};

export default WorkingModelDataFetcher;
