import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import ProgramandCampaignQueryEn from '../QueriesEn/ProgramandCampaignQueryEn';
import ProgramandCampaignQueryHi from '../QueriesHi/ProgramandCampaignQueryHi';

const ProgramandCampaignDataFetcher = ({ setProgramAndCampaignData, currentLanguage }) => {
  const programAndCampaignQuery = currentLanguage === 'en' ? ProgramandCampaignQueryEn : ProgramandCampaignQueryHi;

  const { data } = useQuery(programAndCampaignQuery, {
    fetchPolicy: 'network-only',
    variables: { language: currentLanguage },
  });

  useEffect(() => { 
    if (data) {
      const nodes = data.nodeProgramAndCampaigns.nodes;
      setProgramAndCampaignData(nodes);
      // console.log('Fetched Program and Campaign data:', nodes);
    }
  }, [data, setProgramAndCampaignData]);

  return null;
};

export default ProgramandCampaignDataFetcher;
